import { useEffect, useContext, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
// import Web3Context from "../../contexts/Web3Context";
// import { ethers } from "ethers";
// import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%)",
    backdropFilter: "blur(40px)",
    borderRadius: "25px",
    width: "550px",
    height: "520px",
    textAlign: "center",
    padding: "0px 20px",
  },
  overlay: {
    backgroundColor: "rgba(9, 8, 13, 0.6)",
    zIndex: "20",
  },
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export default function CongratsModal({ whales, open, setModal }) {
  //   const { generateLink, contractDetails } = useContext(Web3Context);
  const [modalWhales, setModalWhales] = useState(null);
  //   const [tokenAddress, setTokenAddress] = useState(ALL_TOKENS["NATIVE"]);
  const [isCustom, setIsCustom] = useState(false);
  const [state, setState] = useState({
    receiver: "0x0000000000000000000000000000000000000000",
  });

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00000";
  }

  return (
    <div>
      {/* {console.log(whales[0]?.name)} */}
      <Modal isOpen={open} onAfterOpen={afterOpenModal} style={customStyles}>
        <Carousel
          swipeable={false}
          draggable={false}
          // showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {whales?.map((whale, idx) => {
            return (
              <div className="mt-16">
                {" "}
                <img
                  src="/img/Close.png"
                  className="absolute right-0 cursor-pointer top-6"
                  onClick={() => setModal(false)}
                />
                <img
                  src={whale.image}
                  className="h-72 rounded-3xl absolute -top-4 left-24"
                />
                <img src="/img/podium.png" className="m-auto h-64 mt-28" />
                <div className="mt-8 m-auto font-medium  ">
                  You have adopted{" "}
                  <span className="text-base-yellow ">{whale.name}</span>
                </div>
                <div className="relative cursor-pointer mt-2">
                  <img src="/img/goldBtn.png" className="h-20 m-auto" />
                  <NavLink
                    to="/whales"
                    className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center "
                  >
                    go to my whales
                  </NavLink>
                </div>
              </div>
            );
          })}

          {/* <div>
            {" "}
            <img
              src="/Close.png"
              className="absolute right-0 cursor-pointer"
              onClick={() => setModal(false)}
            />
            <img
              src={modalWhales[0]?.image}
              className="h-72 rounded-3xl absolute -top-20 left-20"
            />
            <img src="/podium.png" className="m-auto" />
            <div className="mt-8 m-auto font-medium  ">
              You have adopted{" "}
              <span className="text-base-yellow ">{modalWhales[0]?.name}</span>
            </div>
            <div className="relative cursor-pointer mt-2">
              <img src="/goldBtn.png" className="h-20 m-auto" />
              <button className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
                go to my whales
              </button>
            </div>
          </div> */}
        </Carousel>
      </Modal>
    </div>
  );
}
