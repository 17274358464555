import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Web3Context from "../contexts/Web3Context";
import WhalePopup from "../components/WhalePopup";

export default function Whales() {
  const { account, userNFTs } = useContext(Web3Context);
  const [whales, setWhales] = useState(null);
  const [whaleMeta, setWhaleMeta] = useState(null);
  const [balance, setBalance] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  // console.log(userNFTs);
  useEffect(() => {
    const Nfts = async () => {
      setWhales(userNFTs?.data);
      setBalance(userNFTs?.balance);
    };

    account && Nfts();
  }, [account, userNFTs]);

  const getMetadata = async (token) => {
    const res = await axios.get(
      `https://api.doge.atlantys.app/metadata/${token}`
    );
    console.log(res.data);
    setWhaleMeta(res.data);
    setOpen(true);
  };
  // console.log(whales);
  return (
    <>
      <div className="h-screen flex flex-col justify-between  relative bg-whales-hero bg-cover">
        <Navbar page="whales" />

        <div className="whales-box relative  px-20 pt-8  z-40">
          <div className="absolute font-berkshire text-dark-red text-2xl font-medium right-40 top-8">
            whales : <span className="text-base-yellow">{balance}</span>
          </div>
          <div className="text-dark-red mb-8 text-center text-4xl font-black ">
            MY WHALES
          </div>

          {account ? (
            whales && whales?.length !== 0 ? (
              <div className="flex flex-row justify-center mb-6  flex-wrap gap-8 h-80 overflow-auto">
                {whales?.slice(0, 14 + page * 7)?.map((whale, index) => {
                  return (
                    <>
                      <img
                        onClick={() => {
                          getMetadata(whale.tokenId);
                        }}
                        src={whale.tokenImg}
                        className="cursor-pointer h-32 rounded-3xl bg-base-white "
                      />
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="text-center pt-24 font-medium text-2xl text-base-yellow h-64">
                No Whales owned
              </div>
            )
          ) : (
            <div className="text-center pt-24 font-bold text-base-yellow h-64">
              Connect Wallet
            </div>
          )}

          {whales?.length > whales?.slice(0, 14 + page * 7).length && (
            <div
              className="relative cursor-pointer"
              onClick={() => account && setPage((prev) => prev + 1)}
            >
              <img src="/img/goldBtn.png" className="h-16 m-auto" />
              <button className="text-lg md:text-xl absolute top-3 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
                more
              </button>
            </div>
          )}
        </div>
        <div className="absolute bottom-0">
          <img src="/img/mintWaves.png" className="" />
          <img src="/img/mintBottomBg.png" className="absolute bottom-0" />
          {/* <img src="/Bubbles1.png" className="absolute bottom-0" /> */}
        </div>
        <Footer />
      </div>
      <WhalePopup open={open} whale={whaleMeta} setModal={setOpen} />
    </>
  );
}
