import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Web3Context from "../contexts/Web3Context";

export default function Hero() {
  const { account, connectWallet, contractObjects } = useContext(Web3Context);

  return (
    <>
      <div className="pt-40 text-base-white bg-home-hero bg-cover flex justify-between flex-col h-full">
        <div className=" flex flex-row h-64  px-8 md:px-24 items-center justify-between">
          <img src="/img/Mint_whale.png" className="h-72 z-30" />

          {}

          <div className="relative z-30 p-16 connectBtnBg ">
            {account ? (
              <div className="text-5xl text-center font-medium">
                <div className=" gold">CONGRATS!</div>
                <div className=" gold">YOU'RE IN. MINT </div>
                <div className=" gold"> YOUR FIRST WHALE.</div>
                <NavLink to="/mint">
                  <div className="mt-8 cursor-pointer">
                    <img
                      src="/img/greenBtn.png"
                      className="h-20 absolute left-1/2 -translate-x-1/2"
                    />
                    <button className="text-2xl font-berkshire relative  btnTextShadow text-black text-center">
                      mint
                    </button>
                  </div>
                </NavLink>
              </div>
            ) : (
              <div className="text-5xl text-center font-medium">
                <div className=" gold">CONNECT</div>
                <div className=" gold">TO YOUR WALLET</div>
                <div className=" gold">FIRST!</div>
                <div className="mt-8 cursor-pointer" onClick={connectWallet}>
                  <img
                    src="/img/goldBtn.png"
                    className="h-20 absolute left-1/2 -translate-x-1/2"
                  />
                  <button className="text-2xl relative  btnTextShadow text-black text-centerfont-berkshire ">
                    connect
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="h-64"></div> */}
        <img src="/img/waveUp1.png" className="absolute bottom-0 z-0" />
        <img src="/img/waveUp2.png" className="absolute bottom-0 z-10" />

        <NavLink to="/#content" scroll={false}>
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 z-20">
            <div className="bg-base-white/20 w-96 relative  rounded-t-full h-48 cursor-pointer m-auto ">
              <img
                src="/img/arrow.png"
                className="absolute top-20 left-1/2 opacity-100 -translate-x-1/2 h-20"
              />
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}
