import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Web3Context from "../contexts/Web3Context";
import WhalePopup from "../components/WhalePopup";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

export default function Income() {
  const {
    account,
    getUserRewards,
    getWhaleTokenBalance,
    claimUserRewards,
    userNFTs,
  } = useContext(Web3Context);
  const [available, setAvailable] = useState(null);
  const [rewardData, setRewardData] = useState(null);
  const [balance, setBalance] = useState(null);
  const [claimState, setClaimState] = useState(false);
  const [whales, setWhales] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const Rewards = async () => {
      // console.log("esfef");
      setClaimState(false);
      const data = await getUserRewards();
      setRewardData(data);
      let sum = 0;
      //   console.log("data", data);
      for (let i = 0; i < data?.length; i++) {
        sum = sum + parseFloat(data[i]?.reward);
      }
      setAvailable(sum.toFixed(4));
      //   console.log("eufjesf", data);
      const tokenBalance = await getWhaleTokenBalance();
      setBalance(tokenBalance);
    };

    account &&
      setInterval(() => {
        Rewards();
      }, 5000);
  }, [account, claimState]);
  useEffect(() => {
    const getMetadata = async () => {
      //   console.log("run run");
      const whaleData = userNFTs?.data;

      let details = [];
      for (let i = 0; i < whaleData?.length; i++) {
        const token = whaleData[i]?.tokenId;
        const res = await axios.get(
          `https://api.doge.atlantys.app/metadata/${token}`
        );
        const tokenData = {
          tokenId: token,
          image: res?.data?.image,
          name: res?.data?.name,
          species: res?.data?.attributes[2]?.value,
        };
        details.push(tokenData);
        // console.log(details);
      }

      setWhales(details);
    };
    account && getMetadata();
  }, [account, userNFTs]);
  //   console.log(claimTokenIds);
  //   console.log(rewardData);
  const claimAllRewards = async () => {
    try {
      let tokens = [];
      //   console.log(rewardData);
      for (let i = 0; i < rewardData?.length; i++) {
        tokens.push(rewardData[i]?.tokenId);
      }
      const tx = await claimUserRewards(tokens);
      tx && setClaimState(true);
    } catch (error) {
      console.log(error);
    }
  };
  //   console.log(whales);
  return (
    <div className="h-screen flex flex-col justify-between relative bg-income-hero bg-cover">
      <Navbar page="income" />

      <div className="income-box relative  px-12 py-8  z-40">
        {/* <div className="absolute font-berkshire text-dark-red text-2xl font-medium right-40 top-8">
          whales : <span className="text-base-yellow">{balance}</span>
        </div>
        <div className="text-dark-red mb-8 text-center text-4xl font-black ">
          MY WHALES
        </div> */}
        {!whales?.length ? (
          <Loader />
        ) : (
          <div className="flex flex-row gap-24">
            <div className="collectToken-box py-6 flex-0">
              <div className="text-center text-2xl font-bold">Tokens</div>
              <img
                src="/img/incomeToken.png"
                className="h-72 bg-blend-lighten m-auto -mt-10"
              />

              <div className="text-base-white flex flex-row text-xs m-auto w-3/5 -mt-8 mb-2">
                <img src="/img/info.png" className="h-full mr-2" />
                <span>
                  Each Whale NFT will earn you 1 whale token every 24 hours. You
                  can collect your tokens from here.
                </span>
              </div>
              <div
                className="relative cursor-pointer"
                //   onClick={() => account && setPage((prev) => prev + 1)}
              >
                <img src="/img/goldBtnLg.png" className="h-11 mt-10 m-auto" />
                <button
                  onClick={() => claimAllRewards()}
                  className="text-lg md:text-xl absolute top-2 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center "
                >
                  collect all tokens
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-9 flex-1 ">
              <div className="flex flex-row gap-20">
                <div className="text-center">
                  <div>Available tokens</div>
                  <div className="text-5xl font-bold text-base-green mt-2">
                    {available}
                  </div>
                </div>
                <div className="text-center">
                  <div>Total tokens earned</div>
                  <div className="text-5xl font-bold text-base-green mt-2">
                    {balance}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 overflow-auto h-80">
                {" "}
                {whales?.map((token, idx) => {
                  return (
                    <div className="whaleToken-box h-32 flex flex-row items-center justify-between px-6 py-10">
                      <span className="text-lg">{idx + 1}.</span>
                      <div className=" flex flex-row items-center gap-4">
                        <img
                          src={token?.image}
                          className="rounded-full h-20 placeholder-img"
                        />
                        <div className="text-center">
                          <div className="text-lg font-bold ">
                            {token?.name}
                          </div>
                          <div className="text-dark-red text-sm">
                            {token?.species}
                          </div>
                        </div>
                      </div>
                      <div className="text-base-yellow ">
                        {rewardData[idx]?.reward} WHALE TOKENS
                      </div>
                      {/* {rewardData?.map((item) => {
                      return (
                        
                      );
                    })} */}

                      <div
                        className="relative cursor-pointer "
                        //   onClick={() => account && setPage((prev) => prev + 1)}
                      >
                        <img src="/img/goldBtn.png" className="h-14" />
                        <button
                          onClick={() => claimUserRewards([token?.tokenId])}
                          className="text-lg md:text-xl absolute top-2.5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center "
                        >
                          collect
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* {account ? (
          whales && whales?.length !== 0 ? (
            <div className="flex flex-row justify-center  flex-wrap gap-8 h-80 overflow-auto">
              {whales?.slice(0, 14 + page * 7)?.map((whale, index) => {
                return (
                  <>
                    <img
                      onClick={() => {
                        getMetadata(whale.tokenId);
                      }}
                      src={whale.tokenImg}
                      className="cursor-pointer h-32 rounded-3xl bg-base-white "
                    />
                  </>
                );
              })}
            </div>
          ) : (
            <div className="text-center pt-24 font-medium text-2xl text-base-yellow h-64">
              No Whales owned
            </div>
          )
        ) : (
          <div className="text-center pt-24 font-bold text-base-yellow h-64">
            Connect Wallet
          </div>
        )} */}

        {/* <div
          className="relative cursor-pointer"
          onClick={() => account && setPage((prev) => prev + 1)}
        >
          <img src="/img/goldBtn.png" className="h-16 mt-6 m-auto" />
          <button className="text-lg md:text-xl absolute top-3 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
            more
          </button>
        </div> */}
      </div>
      <div className="absolute bottom-0">
        <img src="/img/incomeWaves.png" className="" />
        <img src="/img/mintBottomBg.png" className="absolute bottom-0" />
        {/* <img src="/Bubbles1.png" className="absolute bottom-0" /> */}
      </div>
      <Footer />
      {/* <WhalePopup open={open} whale={whaleMeta} setModal={setOpen} /> */}
    </div>
  );
}
