import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Web3Provider } from "./contexts/Web3Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import Whales from "./pages/Whales";
import ComingSoon from "./pages/ComingSoon";
import Income from "./pages/Income";

function App() {
  return (
    <Web3Provider>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/mint" element={<Mint />} />
          <Route exact path="/whales" element={<Whales />} />
          <Route exact path="/comingsoon" element={<ComingSoon />} />
          <Route exact path="/income" element={<Income />} />
        </Routes>
      </BrowserRouter>
    </Web3Provider>
  );
}

export default App;
