import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Web3Context from "../contexts/Web3Context";

export default function Navbar({ page }) {
  const { account, connectWallet } = useContext(Web3Context);
  useEffect(() => {
    if (!account) {
      connectWallet();
    }
  }, []);
  return (
    <div className="bg-home-nav bg-cover relative z-50">
      <div className="flex text-base-white flex-row px-8 md:px-24 items-center justify-between">
        <div className="text-2xl md:text-3xl lg:text-5xl">
          <img src="/img/AtlantysDogeLogo.png" className="h-32" />
        </div>
        <div className="flex flex-row items-center gap-6 md:gap-12">
          <NavLink to="/">
            <div className="cursor-pointer relative  ">
              <img src="/img/woodenBtn_bg.png" className="h-12" />
              <a
                className={`text-base md:text-lg  div-center font-berkshire cursor-pointer ${
                  page === "home" && "text-base-yellow"
                }`}
              >
                home
              </a>
            </div>
          </NavLink>

          {/* <a className="text-base md:text-lg font-medium cursor-pointer">
            join altantians
          </a> */}
          <NavLink to="/mint">
            <div className="cursor-pointer relative">
              <img
                src="/img/woodenBtnLg_bg.png"
                className="h-12 cursor-pointer"
              />
              <a
                className={`text-base cursor-pointer font-berkshire whitespace-nowrap md:text-lg div-center  cursor-pointer ${
                  page === "mint" && "text-base-yellow"
                }`}
              >
                mint a whale
              </a>
            </div>
          </NavLink>
          <NavLink to="/whales">
            <div className=" relative">
              <img src="/img/woodenBtnLg_bg.png" className="h-12 " />
              <a
                className={`text-base whitespace-nowrap font-berkshire md:text-lg div-center  cursor-pointer ${
                  page === "whales" && "text-base-yellow"
                }`}
              >
                my whales
              </a>
            </div>
          </NavLink>
          <NavLink to="/income">
            <div className=" relative">
              <img src="/img/woodenBtnLg_bg.png" className="h-12 " />
              <a
                className={`text-base whitespace-nowrap font-berkshire md:text-lg div-center  cursor-pointer ${
                  page === "income" && "text-base-yellow"
                }`}
              >
                income
              </a>
            </div>
          </NavLink>
          {/* <a className="text-base md:text-lg lg:text-xl cursor-pointer">
            exchange
          </a>
          <a className="text-base md:text-lg lg:text-xl cursor-pointer">
            liquidity
          </a> */}
          {account ? (
            <div className="relative ">
              <img src="/img/greenBtn.png" className="h-20 " />
              <button className="text-sm md:text-lg font-berkshire absolute top-5 whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center  ">
                wallet connected
              </button>
            </div>
          ) : (
            <div className="relative cursor-pointer" onClick={connectWallet}>
              <img src="/img/goldBtn.png" className="h-20" />
              <div
                onClick={connectWallet}
                className="text-sm md:text-lg font-berkshire cursor-pointer absolute top-5 whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center  "
              >
                connect wallet
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
