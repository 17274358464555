import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Pie from "../components/pie/pie";
import Web3Context from "../contexts/Web3Context";
import { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function Home() {
  const { contractObjects } = useContext(Web3Context);
  const [supply, setSupply] = useState(0);
  useEffect(() => {
    (async () => {
      if (contractObjects) {
        const sup = await contractObjects.mintContract.totalSupply();
        // console.log(sup);
        setSupply(sup.toNumber());
      }
    })();
  }, [contractObjects]);
  return (
    <>
      <div className="h-screen">
        <Navbar page="home" />

        <Hero />
      </div>
      <div className="bg-home-content bg-cover relative" id="content">
        <img
          src="/img/howManyBox.png"
          className="absolute w-3/5 top-28 left-1/2 -translate-x-1/2"
        />
        <img src="/img/Rays.png" className="absolute" />
        <img src="/img/Bubbles1.png" className="absolute z-40" />

        <div className="z-50 text-center relative">
          <img src="/img/mintCountHead.png" className="h-40 m-auto " />

          <div>
            <div className="pie-contain">
              {/* <FadeIn> */}

              <Pie
                caption_jsx={
                  <div className="pie-caption">
                    <span>Mints</span>
                  </div>
                }
                minted={supply}
                total={9000}
              />

              {/* <Pie /> */}
              {/* </FadeIn> */}
            </div>
            <NavLink to="/whales">
              <button className="cursor-pointer text-base mt-8 font-berkshire bg-iceBtn-bg bg-cover  text-black text-center font-medium w-28 py-2">
                my whales
              </button>
            </NavLink>
          </div>
          <div className=" text-base-white mt-16 text-center  px-32">
            <img src="/img/roadmapPlank.png" className="h-40 m-auto " />
            <div className="relative">
              <div className="h-fit text-left roadmapBox text-lg font-regular w-96 absolute left-48 flex flex-col gap-5 py-8  px-12 rounded-lg bg-base-white text-white">
                <span className="text-xl font-medium text-black mt-4">
                  MILESTONE #1
                </span>
                Launch limited NFT staking for the early holders with non LP
                token
              </div>
              <div className="h-fit text-left roadmapBox text-lg font-regular w-96 top-10 right-48 absolute flex flex-col gap-5 py-8  px-12 rounded-lg bg-base-white text-white">
                <span className="text-xl font-medium text-black mt-4">
                  MILESTONE #2
                </span>
                Launch our tokens and allow early stakers to exchange other
                token for this
              </div>
              <div className="h-fit text-left roadmapBox text-lg font-regular w-96 top-56 left-48 absolute flex flex-col gap-5 py-8  px-12 rounded-lg bg-base-white text-white">
                <span className="text-xl font-medium text-black mt-4">
                  MILESTONE #3
                </span>
                Launch game played exclusively with these tokens
              </div>
              <div className="h-fit text-left roadmapBox text-lg font-regular w-96 top-72 right-48 absolute flex flex-col gap-5 py-8  px-12 rounded-lg bg-base-white text-white">
                <span className="text-xl font-medium text-black mt-4">
                  MILESTONE #4
                </span>
                Launch FPS battle royale game
              </div>

              <img src="/img/Tree.png" className="mt-10" />
            </div>
            <div className="relative px-60 font-berkshire">
              <div className="flex-1 font-medium absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <span className="text-3xl  text-base-blue">Join the doge</span>
                <br />
                <span className="text-3xl text-base-blue  berkshire">
                  underwater clan
                </span>
                <br />
                <span className="text-3xl  text-light-orange">
                  be an atlantian
                </span>
                <div className="flex justify-center mt-8  flex-row gap-6">
                  <a
                    target="blank"
                    href="https://t.co/cdKg3HgOkL"
                    className="cursor-pointer"
                  >
                    <div className="flex bg-socialBtn-bg flex-row text-base-darkBlue  items-center gap-4 px-6 py-1.5  rounded-3xl">
                      join discord
                      <img src="/img/discord.png" className="h-5" />
                    </div>
                  </a>
                  <a
                    target="blank"
                    href="https://twitter.com/AtlantysOceans"
                    className="cursor-pointer"
                  >
                    <div className="flex  bg-socialBtn-bg flex-row text-base-darkBlue  items-center gap-4 px-6 py-1.5  rounded-3xl">
                      follow on twitter
                      <img src="/img/twiter.png" className="h-5" />
                    </div>
                  </a>
                </div>
              </div>
              <img src="/img/Scroll.png" className="-mt-28 " />
            </div>

            {/* <div className="mt-16 flex flex-row">
              <div className="border-base-white w-1/2 border-r-4 pl-48 pr-6 text-right border-solid">
                <div className="h-fit text-left roadmapBox text-lg font-regular flex flex-col gap-5 py-12  px-10 rounded-lg bg-base-white text-white">
                  <span className="text-xl font-medium text-black mt-4">
                    For whales from #1 - #2250
                  </span>
                  Launch limited NFT staking for the early holders with non LP
                  token
                </div>
                <div className="h-40 text-left text-lg font-regular flex flex-col gap-5 px-4 mt-32 rounded-lg bg-base-white text-black">
                  <span className="text-xl font-medium text-base-blue mt-4">
                    For whales from #2551 - #4500
                  </span>
                  Launch our tokens and allow early stakers to exchange other
                  token for this
                </div>
              </div>
              <div className="pl-6 w-1/2 pr-48 text-left">
                <div className="h-40 text-left text-lg font-regular flex flex-col gap-5 px-4 mt-32 rounded-lg bg-base-white text-black">
                  <span className="text-xl font-medium text-base-blue mt-4">
                    For whales from #4501 - #6750
                  </span>
                  Launch game played exclusively with these tokens
                </div>
                <div className="h-40 text-left text-lg font-regular flex flex-col gap-5 px-4 mt-32 rounded-lg bg-base-white text-black">
                  <span className="text-xl font-medium text-base-blue mt-4">
                    For whales from #6751 - #9000
                  </span>
                  Launch FPS battle royale game
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="mt-16 bg-base-white flex flex-row items-center  px-56 py-8">
            <img src="/Footer_whales.png" className="h-64 flex-0" />
            <div className="flex-1 font-medium">
              <span className="text-2xl text-base-blue">JOIN THE DOGE</span>
              <br />
              <span className="text-2xl text-base-blue">UNDERWATER CLAN</span>
              <br />
              <span className="text-2xl text-light-orange">
                BE AN ATLANTIAN
              </span>
              <div className="flex justify-center mt-8  flex-row gap-6">
                <div className="flex flex-row items-center gap-4 px-6 py-1.5 bg-base-blue rounded-3xl">
                  join twitter
                  <a className="cursor-pointer">
                    <img src="/discord.png" className="h-5" />
                  </a>
                </div>
                <div className="flex flex-row items-center gap-4 px-6 py-1.5 bg-base-blue rounded-3xl">
                  join discord
                  <a className="cursor-pointer">
                    <img src="/twiter.png" className="h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="relative -mt-96">
          <img src="/img/homeBottomBg.png" className="" />
          <img src="/img/Bubbles1.png" className="absolute bottom-0" />
          <Footer />
        </div>
      </div>
    </>
  );
}
