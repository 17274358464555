import { useEffect, useContext, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rarityData from "./whaleRarity.json";
// import Web3Context from "../../contexts/Web3Context";
// import { ethers } from "ethers";
// import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%)",
    backdropFilter: "blur(24px)",
    borderRadius: "25px",
    width: "550px",
    height: "480px",
    textAlign: "center",
    padding: "0px 20px",
  },
  overlay: {
    backgroundColor: "rgba(9, 8, 13, 0.6)",
    zIndex: "50",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export default function WhalePopup({ whale, open, setModal }) {
  //   const { generateLink, contractDetails } = useContext(Web3Context);
  const [rarity, setRarity] = useState({
    Background: null,
    Accessory: null,
    Headwear: null,
    Weapon: null,
    Colour: null,
    Eye: null,
  });
  useEffect(() => {
    setRarity({
      Background: rarityData.Background[whale?.attributes[1].value],
      Accessory: rarityData.Accessory[whale?.attributes[3].value],
      Headwear: rarityData.Headwear[whale?.attributes[4].value],
      Weapon: rarityData.Weapon[whale?.attributes[5].value],
      Colour: rarityData.Color[whale?.attributes[6].value],
      Eye: rarityData.Eye[whale?.attributes[7].value],
    });
  }, [whale]);
  //   const [tokenAddress, setTokenAddress] = useState(ALL_TOKENS["NATIVE"]);
  const [isCustom, setIsCustom] = useState(false);
  const [state, setState] = useState({
    receiver: "0x0000000000000000000000000000000000000000",
  });

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00000";
  }
  // console.log(rarityData.Color);
  return (
    <div>
      {/* {console.log(whales[0]?.name)} */}
      <Modal isOpen={open} onAfterOpen={afterOpenModal} style={customStyles}>
        <img
          src="/img/Close.png"
          className="absolute right-5 cursor-pointer top-6"
          onClick={() => setModal(false)}
        />
        <div className="mt-14">
          {" "}
          <div className="text-white text-2xl mb-2 font-medium">
            {whale?.name}
          </div>{" "}
          <div className="text-dark-red text-base">
            {whale?.attributes[2].value}
          </div>
          <div className="flex flex-row justify-between  items-center px-4 mt-8">
            <img src={whale?.image} className="h-56 mr-2 rounded-3xl" />

            <div>
              <div className="text-white text-center mb-4 font-bold">
                Attributes
              </div>

              <div className="text-white text-sm text-left mb-4 font-bold">
                Background:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {" "}
                  {whale?.attributes[1].value}
                </span>
              </div>
              <div className="text-white text-sm text-left mb-4 font-bold">
                Accessory:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {" "}
                  {whale?.attributes[3].value}
                </span>
              </div>
              <div className="text-white text-sm text-left mb-4 font-bold">
                Headwear:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {" "}
                  {whale?.attributes[4].value}
                </span>
              </div>

              <div className="text-white text-sm text-left mb-4 font-bold">
                Weapon:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {" "}
                  {whale?.attributes[5].value}
                </span>
              </div>
              <div className="text-white text-sm text-left mb-4 font-bold">
                Colour:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {" "}
                  {whale?.attributes[6].value}
                </span>
              </div>
              <div className="text-white text-sm text-left mb-4 font-bold">
                Eye:{" "}
                <span className="text-dark-red ml-1 font-normal">
                  {whale?.attributes[7].value}
                </span>
              </div>
            </div>
            <div>
              <div className="text-white text-center mb-4 font-bold">
                Rarity
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Background}
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Accessory}
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Headwear}
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Weapon}
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Colour}
              </div>
              <div className="text-dark-red  text-sm mb-4 font-normal">
                {rarity?.Eye}
              </div>
            </div>
          </div>
          {/* <div className="mt-4 m-auto font-medium  ">
            <span className="text-base-yellow text-lg">{whale?.name}</span>
          </div> */}
          {/* <div className="relative cursor-pointer mt-2">
            <img src="/goldBtn.png" className="h-20 m-auto" />
            <button className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
              go to my whales
            </button>
          </div> */}
        </div>

        {/* <div>
            {" "}
            <img
              src="/Close.png"
              className="absolute right-0 cursor-pointer"
              onClick={() => setModal(false)}
            />
            <img
              src={modalWhales[0]?.image}
              className="h-72 rounded-3xl absolute -top-20 left-20"
            />
            <img src="/podium.png" className="m-auto" />
            <div className="mt-8 m-auto font-medium  ">
              You have adopted{" "}
              <span className="text-base-yellow ">{modalWhales[0]?.name}</span>
            </div>
            <div className="relative cursor-pointer mt-2">
              <img src="/goldBtn.png" className="h-20 m-auto" />
              <button className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
                go to my whales
              </button>
            </div>
          </div> */}
      </Modal>
    </div>
  );
}
