import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

export default function Pie({ minted, total }) {
  const data = {
    labels: ["Treasury", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        data: [minted, total - minted],
        backgroundColor: ["#FFB800", "rgba(50, 50, 50, 0.2)"],
        borderColor: ["#FFB800"],
        borderWidth: 1,
        borderAlign: "inner",
        cutout: "80%",
      },
    ],
  };

  return (
    <div className="pie-wrap mt-10 m-auto">
      <Doughnut data={data} />
      <div className="inner-content">
        <div className="data-stats">
          {minted}
          <div className="small-cap">/ {total}</div>
        </div>
      </div>
    </div>
  );
}
