import { createContext, useEffect, useState } from "react";
import { ethers, Contract } from "ethers";
import axios from "axios";
import {
  Provider as MulticallProvider,
  Contract as MulticallContract,
} from "ethers-multicall";
import { BigNumber, utils } from "ethers";
import { toast } from "react-toastify";
import mintAbi from "./mintAbi.json";
import emissionAbi from "./emissionAbi.json";
import whaleTokenAbi from "./whaleTokenAbi.json";

const Web3Context = createContext();

const RPC_URL = "https://rpc01-sg.dogechain.dog";
const CHAIN_ID = 2000;
const CHAIN_NAME = "DogeChain";

// const RPC_URL = "https://rpc-testnet.dogechain.dog";
// const CHAIN_ID = 568;
// const CHAIN_NAME = "DogeChain Testnet";

const NATIVE_CURRENCY = {
  name: "DOGE",
  symbol: "DOGE",
  decimals: 18,
};
//mainnet
const MULTI_CALL_ADDRESS = "0xFd3089C922fb9694ffC06b4c4cD0deBADF09CF94";
const MINT_CONTRACT_ADDRESS = "0x0fe895eE9763e6000A8C86B7d5f1555a9Ce4cE6e";
const EMISSION_CONTRACT_ADDRESS = "0x33C71A299AEBB038e4442AFB79a1B55343cfE2C9";
const WHALE_TOKEN_CONTRACT_ADDRESS =
  "0x037D27fE8469Be945aC6638Ba7B7505397970C65";
//testnet
// const MINT_CONTRACT_ADDRESS = "0xdFBD4ADFEdAD7c0EE537359E4d6439609806f92E";
// const MULTI_CALL_ADDRESS = "0x037D27fE8469Be945aC6638Ba7B7505397970C65";
// const EMISSION_CONTRACT_ADDRESS = "0x404c5bA1ac4600e8CB085b3826FbE35EDBB9a7C8";
// const WHALE_TOKEN_CONTRACT_ADDRESS =
//   "0xBbCF32d7Fb9349ea30FDACA9C0c415ea29dD9636";

// const RPC_URL = "https://rpc.hermesdefi.io/";
// const CHAIN_ID = 1666600000;
// const NATIVE_CURRENCY = {
//   name: "one",
//   symbol: "ONE", // 2-6 characters long
//   decimals: 18,
// };
// const MULTI_CALL_ADDRESS = "0x34b415f4d3b332515e66f70595ace1dcf36254c5";
// const CHAIN_NAME = "Harmony Mainnet";
// const BASE_URL = "http://localhost:5000/proposal";

export const Web3Provider = (props) => {
  const [account, setAccount] = useState();
  const [signer, setSigner] = useState();
  const [contractObjects, setContractObjects] = useState();
  const [userNFTs, setUserNFTs] = useState(null);
  const [minted, setMinted] = useState(false);

  const functionsToExport = {};
  const onAccountsChanged = async (accounts) => {
    setAccount(accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };

  useEffect(() => {
    try {
      const _signer =
        signer || new ethers.providers.Web3Provider(window.ethereum, "any");
      const mintContract = new ethers.Contract(
        MINT_CONTRACT_ADDRESS,
        mintAbi,
        _signer
      );
      const emissionContract = new ethers.Contract(
        EMISSION_CONTRACT_ADDRESS,
        emissionAbi,
        _signer
      );
      const whaleTokenContract = new ethers.Contract(
        WHALE_TOKEN_CONTRACT_ADDRESS,
        whaleTokenAbi,
        _signer
      );
      const _contractObjects = {
        mintContract,
        emissionContract,
        whaleTokenContract,
      };
      setContractObjects(_contractObjects);
    } catch (e) {
      console.log(e);
    }
  }, [signer]);

  const addNewChain = async () => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${CHAIN_ID.toString(16)}`,
          rpcUrls: [RPC_URL],
          chainName: CHAIN_NAME,
          nativeCurrency: NATIVE_CURRENCY,
        },
      ],
    });
  };
  const switchCain = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });
  };
  const promptChain = async () => {
    try {
      await switchCain();
    } catch (e) {
      await addNewChain();
      // await switchCain();
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  const onChainChanged = async (chainID) => {
    await promptChain();
  };

  const setupMultiCallContract = async (contractAddress, abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const ethcallProvider = new MulticallProvider(provider);

    await ethcallProvider.init();
    ethcallProvider._multicallAddress = MULTI_CALL_ADDRESS;

    const multicallContract = new MulticallContract(contractAddress, abi);
    return [ethcallProvider, multicallContract];
  };

  functionsToExport.connectWallet = async (defaultAccount = -1) => {
    const { ethereum } = window;
    if (!ethereum) {
      toast.error("You need a wallet to continue!");
      return;
    }

    if (ethereum) {
      await ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await ethereum.request({ method: "eth_accounts" });
      await promptChain();
      ethereum.on("chainChanged", onChainChanged);
      ethereum.on("accountsChanged", onAccountsChanged);
      setAccount(accounts[0]);
      toast.success("Wallet Connected!");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = provider.getSigner();
      setSigner(_signer);
    }
  };

  functionsToExport.mint = async (count) => {
    try {
      if (count < 1) {
        return toast.error("Minimum Mint amount is 1 NFT");
      }

      const mintContract = contractObjects.mintContract;

      if (count > 10) return toast.error("Quantity must be less then equal 10");
      toast.loading("Placing transaction");
      const price = await mintContract.PRICE();
      const maxSupply = 9000;
      const totalSupply = await mintContract.totalSupply();
      if (maxSupply - totalSupply - count >= 0) {
        const tx = await mintContract.mint(count, {
          value: price.mul(count),
        });
        const txDetails = await tx.wait();

        const logs = txDetails.logs;
        let mintedWhales = [];
        for (let i = 0; i < logs.length; i++) {
          const token = parseInt(logs[i].topics[3]);
          const details = await axios.get(
            `https://api.doge.atlantys.app/metadata/${token}`
          );

          const whaleData = {
            image: `https://api.doge.atlantys.app/transparent/${token}`,
            name: details.data.name,
          };
          mintedWhales.push(whaleData);
        }
        setMinted(true);
        toast.dismiss();
        toast.success("Purchase Complete");
        return mintedWhales;
      } else {
        toast.dismiss();
        toast.error(`Only ${maxSupply - totalSupply} NFTs Remaining`);
      }
    } catch (e) {
      toast.dismiss();
      console.log(e.message);
    }
  };
  // console.log(contractObjects);
  functionsToExport.getNfts = async () => {
    try {
      const userBalance = parseInt(
        (await contractObjects?.mintContract?.balanceOf(account)).toString()
      );
      // console.log(userBalance?.toString());
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(MINT_CONTRACT_ADDRESS, mintAbi);
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      const userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      // return [1, 2, 3, 4, 5, 67];
      const userTokenDetails = await Promise.all(
        userTokens?.map(async (token) => {
          // const res = await axios.get(
          //   `https://api.doge.atlantys.app/metadata/${token}`
          // );
          // console.log(res);
          const tokenData = {
            tokenImg: `https://api.doge.atlantys.app/image/${token}`,
            tokenId: token,
          };
          return tokenData;
        })
      );
      // console.log(userTokenDetails);
      return { data: userTokenDetails, balance: userBalance?.toString() };
    } catch (e) {
      console.log(e);
    }
  };
  // account && console.log(functionsToExport.price());
  functionsToExport.price = async () => {
    const mintContract = contractObjects.mintContract;

    let price = await mintContract.PRICE();
    return ethers.utils.formatEther(price.toString());
  };

  functionsToExport.getUserRewards = async () => {
    try {
      const userBalance = parseInt(
        (await contractObjects?.mintContract?.balanceOf(account)).toString()
      );
      // console.log(userBalance?.toString());
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(MINT_CONTRACT_ADDRESS, mintAbi);
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      const userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        parseInt(e.toString())
      );
      const userTokenDetails = await Promise.all(
        userTokens?.map(async (token) => {
          // const res = await axios.get(
          //   `https://gen1.atlantys.one/token/metadata/${token}`
          // );
          // console.log(res);
          const tokenData = {
            tokenId: token,
          };
          return tokenData;
        })
      );
      // console.log(userTokenDetails);

      const [multicallProvider2, multicallContract2] =
        await setupMultiCallContract(EMISSION_CONTRACT_ADDRESS, emissionAbi);
      let tokenCalls2 = [];
      for (let i = 0; i < userTokens.length; i++) {
        tokenCalls2.push(multicallContract2.getRewards(userTokens[i]));
      }
      const rewards = (await multicallProvider2?.all(tokenCalls2)).map((e) =>
        parseFloat(utils?.formatEther(e.toString())).toFixed(4)
      );
      for (let i = 0; i < userTokenDetails.length; i++) {
        userTokenDetails[i].reward = rewards[i];
      }
      // console.log(rewards);

      // rewards = utils?.formatEther(result?.toString());
      return userTokenDetails;
    } catch (e) {
      console.log(e);
    }
  };

  functionsToExport.claimUserRewards = async (tokenIds) => {
    try {
      toast.loading("Placing transaction");
      const tx = await contractObjects?.emissionContract?.claimRewards(
        tokenIds
      );
      console.log(tx);
      toast.dismiss();
      toast.success("Rewards claimed");
      return tx;
    } catch (e) {
      toast.dismiss();
      console.log(e.message);
    }
  };

  functionsToExport.getWhaleTokenBalance = async () => {
    try {
      const userBalance = parseFloat(
        utils?.formatEther(
          await contractObjects?.whaleTokenContract?.balanceOf(account)
        )
      ).toFixed(4);

      return userBalance;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const NFTS = async () => {
      const nftData = await functionsToExport.getNfts();

      setUserNFTs(nftData);
      minted && setMinted(false);
    };
    account && NFTS();
  }, [account, minted]);
  // console.log(userNFTs);
  // functionsToExport.
  return (
    <Web3Context.Provider
      value={{
        account,
        contractObjects,
        signer,
        userNFTs,
        ...functionsToExport,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
export default Web3Context;
