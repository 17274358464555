import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Pie from "../components/pie/pie";
import Web3Context from "../contexts/Web3Context";
import Clock from "../components/clock/clock";
import { useContext, useEffect, useState } from "react";

export default function ComingSoon() {
  const { contractObjects } = useContext(Web3Context);
  const [supply, setSupply] = useState(0);
  useEffect(() => {
    (async () => {
      if (contractObjects) {
        const sup = await contractObjects.mintContract.totalSupply();
        console.log(sup);
        setSupply(sup.toNumber());
      }
    })();
  }, []);
  return (
    <div className="h-screen bg-soon-hero bg-cover">
      <Navbar page="mint" />

      <div className="absolute bottom-0">
        <img src="/img/soonWaves.png" className="" />
        <img src="/img/mintBottomBg.png" className="absolute bottom-0" />
        {/* <img src="/Bubbles1.png" className="absolute bottom-0" /> */}
        <Footer />
      </div>
    </div>
  );
}
