import { useContext, useEffect, useState } from "react";
import CongratsModal from "../components/CongratsModal";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Web3Context from "../contexts/Web3Context";

export default function Mint() {
  const { account, mint, connectWallet, price } = useContext(Web3Context);
  const [nftPackValue, setNftPackValue] = useState(1);
  const [subTotal, setSubTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [whales, setWhales] = useState([]);

  function NftPack({ value, setValue }) {
    return (
      <div
        className={` flex items-center font-berkshire justify-center cursor-pointer nft-select-value text-2xl ${
          nftPackValue === value && "nft-selected"
        }`}
        onClick={() => setValue(value)}
      >
        {value}
      </div>
    );
  }

  useEffect(() => {
    const f = async () => {
      if (account) {
        const total = await NFTprice(nftPackValue);
        setSubTotal(total);
      }
    };
    f();
  }, [nftPackValue]);

  async function NFTprice(amount) {
    const pr = await price();
    return (amount * parseFloat(pr)).toString();
  }

  async function mintNFT() {
    const mintedWhales = await mint(nftPackValue);
    console.log("mint whales", mintedWhales);
    setWhales([...mintedWhales]);
    setModalOpen(true);
  }

  return (
    <>
      <div className="h-screen flex flex-col justify-between relative bg-mint-hero bg-cover">
        <Navbar page="mint" />
        <div className="flex flex-row px-32 justify-between items-center">
          <img src="/img/Excited_whale.png" className="h-80 relative z-10" />
          <div className="text-center select-box px-12 py-10 relative z-10">
            <div className="text-3xl font-medium">Select Quantity</div>
            <div className="flex flex-row gap-8 mt-8">
              <NftPack value={1} setValue={setNftPackValue} />
              <NftPack value={3} setValue={setNftPackValue} />
              <NftPack value={5} setValue={setNftPackValue} />
              <NftPack value={10} setValue={setNftPackValue} />
            </div>
            <hr className=" mt-16 m-auto w-10/12"></hr>
            <div className="flex m-auto font-medium flex-row justify-between mt-8">
              <div className="text-xl text-base-white">Sub total</div>
              <div className="text-xl" style={{ color: "#C7FFBF" }}>
                {account ? subTotal : "$$$"} DOGE
              </div>
            </div>
            {account ? (
              <div className="relative cursor-pointer mt-10" onClick={mintNFT}>
                <img src="/img/goldBtn.png" className="h-20 m-auto" />
                <button className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
                  mint nft
                </button>
              </div>
            ) : (
              <div
                className="relative cursor-pointer mt-10"
                onClick={connectWallet}
              >
                <img src="/img/goldBtn.png" className="h-20 m-auto" />
                <button className="text-lg md:text-xl absolute top-5 font-berkshire whitespace-nowrap left-1/2 -translate-x-1/2 btnTextShadow text-black text-center ">
                  connect wallet
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="absolute bottom-0">
          <img src="/img/mintWaves.png" className="" />
          <img src="/img/mintBottomBg.png" className="absolute bottom-0" />
          {/* <img src="/Bubbles1.png" className="absolute bottom-0" /> */}
        </div>
        <Footer />
        {/* <div className="bg-mint-footer px-72 items-center justify-between h-28 mt-12 flex flex-row">
        <div className="text-base">copyright © 2022</div>
        <div className="flex flex-row gap-6">
          <div className="text-lg">join the clan </div>
          <a className="cursor-pointer">
            <img src="/discord.png" className="h-6" />
          </a>
          <a className="cursor-pointer">
            <img src="/twiter.png" className="h-6" />
          </a>
        </div>
      </div> */}
      </div>
      <CongratsModal open={modalOpen} whales={whales} setModal={setModalOpen} />
    </>
  );
}
