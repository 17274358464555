import React, { useState } from "react";

export default function Footer() {
  return (
    <div className="footerBox backdrop-blur-xl  font-berkshire flex flex-row px-56 justify-between w-screen text-xl py-6 text-center">
      <div>copyright @2022</div>
      <div className="flex flex-row gap-5 items-center justify-center">
        <span>join the clan</span>
        <a
          href="https://t.co/cdKg3HgOkL"
          target="blank"
          className="cursor-pointer"
        >
          <img src="/img/discordWhite.png" className="h-6" />
        </a>
        <a
          href="https://twitter.com/AtlantysOceans"
          target="blank"
          className="cursor-pointer"
        >
          <img src="/img/twiterWhite.png" className="h-6" />
        </a>
      </div>
    </div>
  );
}
